import React from "react";
import { Layout } from "../../components/Layout";
import { HeaderBuffer } from "../../components/Layout/Header";
import biblePages from "../../images/bible-pages.jpg";

const SmallGroupPage = () => {
  return (
    <Layout>
      <main className="min-h-[80vh]">
        <div className="bg-cyan-950">
          <HeaderBuffer />
        </div>
        <div className="w-full px-8 py-16 md:px-16 md:py-16 bg-cyan-900">
          <h1 className="text-white text-2xl text-left mb-4">Small Group</h1>
          <h2 className="text-white text-5xl font-serif text-left">
            Learning how to follow Jesus together
          </h2>
        </div>
        <div className="p-8 md:p-16 grid grid-flow-row grid-cols-1 md:grid-cols-2 items-center gap-8 md:gap-16">
          <div>
            <div className=" text-cyan-900 text-2xl font-light">
              Join us for fellowship and study!
            </div>
            <div className="text-gray-500 text-lg">
              <span className="font-bold text-gray-600">When: </span>Every
              Tuesday. Fellowship and snacks at 6:30pm. Bible study starts at
              7pm.
            </div>
            <div className="text-gray-500 text-lg">
              <span className="font-bold text-gray-600">Where: </span>Chunat's
              Home -{" "}
              <a
                href="https://maps.app.goo.gl/BedPbtKamiq83ek8A"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500 hover:underline"
              >
                13812 Parkmont Street, Alliance, Ohio 44601
              </a>
            </div>
            <div className="text-gray-500 text-lg">
              <span className="font-bold text-gray-600">What: </span>We are
              currently studying the book of Hebrews.{" "}
              <a
                href="https://a.co/d/5Cl7Fp9"
                target="_blank"
                rel="noreferrer"
                className="text-blue-500 hover:underline"
              >
                Link to study guide.
              </a>
            </div>
          </div>
          <div>
            <img
              src={biblePages}
              className="object-contain rounded-2xl shadow-xl"
            />
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default SmallGroupPage;
